module controllers {
    export module master {
        interface IProductUpdateScope extends ng.IScope {
        }

        interface IProductUpdateParams extends ng.ui.IStateParamsService {
            proId: number;
        }

        export class productUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'productTypeService',
                'classificationValueService',
                'countryService',
                'statisticalUnitService',
                'documentRepositoryService',
                'uNDGCodeService',
                'customAttributeService',
                'customAttributeAnswerOptionService',
                'lookupService',
                'productService',
                'unitOfMeasureService',
                "supplierService",
                "vatRateService",
                "localeService",
                "productTariffService",
                "menuService"
            ];


            proId: number;
            productMaster: interfaces.master.IProductMaster;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;

             //ProductSupplier Grid
            apiProductSupplier: uiGrid.IGridApi;

            //ProductVatRate Grid
            apiProductVatRate: uiGrid.IGridApi;

            //Product Additional Desc Grid
            apiProductDesc :  uiGrid.IGridApi;

            //Attribute Grid
            apiPA: uiGrid.IGridApi;
            attributeList: interfaces.applicationcore.IDropdownModel[] = []; 
            attributeAnswerOptionList: interfaces.applicationcore.IDropdownModel[] = []; 

            productImageFile : string = "";
            labelImageFile  : string = "";
            AdditionalImage1File  : string = "";
            AdditionalImage2File : string = "";

            hasTariffingRights: boolean = false;

            constructor(
                private $scope: IProductUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IProductUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public productTypeService: services.master.productTypeService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public countryService: interfaces.applicationcore.ICountryService,
                public statisticalUnitService: interfaces.tariff.IStatisticalUnitService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public UNDGCodeService: services.applicationcore.uNDGCodeService,
                public customAttributeService: services.master.customAttributeService,
                public customAttributeAnswerOptionService: services.master.customAttributeAnswerOptionService,
                public lookupService: interfaces.applicationcore.ILookupService,
                public productService: interfaces.master.IProductService,
                public unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private supplierService: interfaces.master.ISupplierService,
                public vatRateService: interfaces.master.IVatRateService,
                private localeService: interfaces.applicationcore.ILocaleService,
                public productTariffService: services.tariff.productTariffService,
                private menuService: interfaces.applicationcore.IMenuService,
            ) {

                this.proId = $stateParams.proId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadProduct(this.proId));
                loadPromises.push(this.getHasTariffingViewRights());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pro.update'
                },
                    () => {                        
                        return $q.all(loadPromises);
                    });

            }

            getHasTariffingViewRights() {
                this.hasTariffingRights = false;
                return this.menuService.getGTSConnectMenuItem(99).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasTariffingRights = true;
                    }
                }).$promise;
            }

            loadProduct(Id: number) {

                return this.productService.getProduct().query({ Id: Id }, (data: interfaces.master.IProductMaster) => {

                    this.productMaster = data;
                    
                    if (this.proId === 0)
                    {
                        this.breadCrumbDesc = "Create New";
                    }
                    else
                    {
                        this.breadCrumbDesc = this.productMaster.Code + " - " + this.productMaster.ShortDescription;
                    }

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwProductSupplier.data = this.productMaster.ProductSupplierList;
                        this.gvwProductVatRate.data = this.productMaster.ProductVatRateList;
                        this.gvwProductDesc.data = this.productMaster.ProductAdditionalDescriptionList;
                        this.gvwProductAttribute.data = this.productMaster.ProductAttributeList;
                        this.productImageFile = this.productMaster.ProductImageFile ? this.productMaster.ProductImageFile.base64 : "";
                        this.labelImageFile = this.productMaster.LabelImageFile ? this.productMaster.LabelImageFile.base64 : "";
                        this.AdditionalImage1File = this.productMaster.AdditionalImage1File ? this.productMaster.AdditionalImage1File.base64 : "";
                        this.AdditionalImage2File = this.productMaster.AdditionalImage2File ? this.productMaster.AdditionalImage2File.base64 : "";
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;

            }

            public loadVatRates() {
                if (this.productMaster) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.productMaster.OwnerEntityId }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            public LoadLocales(){
               return  this.localeService.getLocale().query({}, (locales: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {}
                , (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }


            loadProductTypes() {
                if (this.$rootScope.Entity.Id) {
                    return this.productTypeService.getDropdownList().query({ ownerEntityId: this.$rootScope.Entity.Id }, () => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        return this.productTypeService.getDropdownList().query({ ownerEntityId: resultEntity.Id }, () => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }
            }

            loadUoMList() {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.productMaster.OwnerEntityId }).$promise;
            }

            
            LoadProductCostGroups(){
                return this.productService.getProductCostGroupList(this.productMaster.OwnerEntityId ).query({ ownerEntityId: this.productMaster.OwnerEntityId }).$promise;               
            }

            
            loadStatuses() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ProductStatus
                }, (resultList) => {
                       }).$promise;
            }

          

            productStatusViewHistory_Click(){
                this.DisplayProductStatusHistory(this.productMaster.ProductStatusHistoryList);                
            }

            DisplayProductStatusHistory(statusHistoryList: Array<interfaces.master.IProductStatusHistory>) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="productStatusHistory">
                                <div class="gts-padding">
                                    <fieldset id="productStatusHistory">
                                    <legend>
                                       Product Status History
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                            <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Old Description</th>
                                                                                <th>New Description</th>
                                                                                <th>Comments</th>
                                                                                <th>User</th>
                                                                                <th>Date and Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr ng-repeat="statusHistory in productStatusHistoryCtrl.statusHistoryList">                    
                                                                                <td>{{statusHistory.OldStatusDescription}}</td>
                                                                                <td>{{statusHistory.NewStatusDescription}}</td>
                                                                                <td>{{statusHistory.Comments}}</td>
                                                                                <td>{{statusHistory.User}}</td>
                                                                                <td>{{statusHistory.UpdateDate | momentDateFilter: "yyyy/MM/dd HH:mm:ss"}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                            </table>                     
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="productStatusHistoryCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class productStatusHistoryCtrl {
                        statusHistoryList: Array<interfaces.master.IProductStatusHistory>;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                            this.statusHistoryList = statusHistoryList;
                        }

                        ok() {

                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "productStatusHistoryCtrl",
                    size: "lg",
                    resolve: {
                    }
                }).result;
            }

            registerProductSupplierApi(gridApi: uiGrid.IGridApi) {
                this.apiProductSupplier = gridApi;
            }
            
            insertProductSupplier() {
                this.productMaster.ProductSupplierList.push(<interfaces.master.IProductMasterSupplier>{
                    Id: 0,
                    productId: this.productMaster.Id,
                    IsActive: true,
                    IsDefault: this.productMaster.ProductSupplierList.length==0?true:false
                });
            }

            productSupplierChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.IProductMasterSupplier) {
                if (model) {
                    entity.Supplier.Id = model.Id;
                    entity.Supplier.Code = model.Code;
                    entity.Supplier.Description = model.Description;                    
                }
            }

             setRowsDirtyProductSupplier(entity) {
                if (this.apiProductSupplier) {
                    this.apiProductSupplier.rowEdit.setRowsDirty([entity]);
                }
            }

            loadSuppliers(searchText: string) {
                if (this.productMaster) {
                    return this.supplierService.getSupplierDropdown(this.productMaster.OwnerEntityId, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            gvwProductSupplier: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowHeight: 34,
                rowEditWaitInterval: -1,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell"  ui-grid-cell></div>',
                onRegisterApi: (gridApi) => { this.registerProductSupplierApi(gridApi); },               
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productUpdateCtrl.removeInlineSupplier(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 56,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productUpdateCtrl.insertProductSupplier()">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Supplier',
                        displayName: '*Supplier',
                        field: 'Supplier',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown  required="true" pagination-search="true" refresh-delay="1000" ng-change="grid.appScope.productUpdateCtrl.productSupplierChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productUpdateCtrl.loadSuppliers(searchText)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 500
                    } , {
                        name: "Default",
                        displayName: "Default",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,                        
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.IsDefault" ng-change="grid.appScope.productUpdateCtrl.setRowsDirtyProductSupplier(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 100
                    }
                    , {
                        name: "Active",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,                        
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive" ng-change="grid.appScope.productUpdateCtrl.setRowsDirtyProductSupplier(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 100
                    }
                ]
            };


            registerProductVatRateApi(gridApi: uiGrid.IGridApi) {
                this.apiProductVatRate = gridApi;
            }
            
            insertProductVatRate() {
                this.productMaster.ProductVatRateList.push(<interfaces.master.IProductVatRate>{   
                    Id : 0,                 
                    ProductId: this.productMaster.Id,
                    StartDate :  moment().utc(),
                    IsActive: true
                });
            }

            productVatRateChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.IProductVatRate) {
                if (model) {
                    this.setRowsDirtyVR(entity);
                }
            }

              setRowsDirtyVR(entity) {
                if (this.apiProductVatRate) {
                    this.apiProductVatRate.rowEdit.setRowsDirty([entity]);
                }
            }


            gvwProductVatRate: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerProductVatRateApi(gridApi); },               
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-show="row.entity.Id === 0" ng-click="grid.appScope.productUpdateCtrl.removeInlineVatRate(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 56,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productUpdateCtrl.insertProductVatRate()">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'STARTDATE',
                        displayName: 'Start Date',
                        field: 'StartDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                            <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-startDate" ng-required="true"></gts-date>
                                    </form>`,
                        enableSorting: false,
                        width: 150
                    }, {
                        name: 'ENDDATE',
                        displayName: 'End Date',
                        field: 'EndDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                            <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                    </form>`,
                        enableSorting: false,
                        width: 150
                    }, 
                    {
                        name: 'VatRateCode',
                        displayName: '*VAT Rate',
                        field: 'VatRate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown  required="true" pagination-search="false" refresh-delay="200" ng-change="grid.appScope.productUpdateCtrl.productVatRateChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productUpdateCtrl.loadVatRates()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 500
                    } , {
                        name: "Rate",
                        displayName: "Rate",
                        field: 'CurrentVatRate',
                        enableFiltering: false,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,                        
                        cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD}}</div>`,
                        width: 100
                    }, {
                        name: "CountryCode",
                        displayName: "Country",
                        field: 'CountryDescription',
                        enableFiltering: false,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,                        
                        cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD}}</div>`,
                        width: 150
                    }
                   
                ]
            };

            
            registerProductDesc(gridApi: uiGrid.IGridApi) {
                this.apiProductDesc = gridApi;
            }
            

            insertProductDesc() {
                this.productMaster.ProductAdditionalDescriptionList.push(<interfaces.master.IProductAdditionalDescription>{
                    productId: this.productMaster.Id,
                    IsActive: true
                });
            }

            productDescChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.IProductAdditionalDescription) {
                if (model) {
                    entity.LocaleCode.Id = model.Id;
                    entity.LocaleCode.Code = model.Code;
                    entity.LocaleCode.Description = model.Description;                    
                }
            }

            gvwProductDesc: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerProductDesc(gridApi); },               
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productUpdateCtrl.removeInlineAdditionalDescription(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 56,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productUpdateCtrl.insertProductDesc()">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'LocaleCode',
                        displayName: '*Locale Code',
                        field: 'LocaleCode',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown  required="true" pagination-search="false" refresh-delay="100" ng-change="grid.appScope.productUpdateCtrl.productDescChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productUpdateCtrl.LoadLocales()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 200
                    }, {
                        name: 'ShortDescription',
                        displayName: '*Short Description',
                        field: 'ShortDescription',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{row.entity.ShortDescription}}</div>`,
                        enableSorting: false,
                        enableCellEdit: true,
                        editableCellTemplate: `
                                   <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-ShortDescription" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        width: 300
                    }, {
                        name: 'LongDescription',
                        displayName: 'Long Description',
                        field: 'LongDescription',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{row.entity.LongDescription}}</div>`,
                        enableSorting: false,
                        enableCellEdit: true,
                        editableCellTemplate: `
                                   <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-LongDescription" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        width: 300
                    } , 
                     {
                        name: "Active",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,                        
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive" ng-change="grid.appScope.productUpdateCtrl.setRowsDirtyProductSupplier(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 100
                    }
                ]
            };

            removeInlinePA(entity: interfaces.master.ICustomAttributeValue) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                }, () => {
                    return this.customAttributeService.deleteCustomAttributeValue(entity.Id).save({ Id: entity.Id }, (result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'pt.update'
                            }, () => {
                                this.loadProduct(this.proId);
                            });
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
                });

                if (this.apiPA) {
                    this.apiPA.rowEdit.setRowsClean(this.productMaster.ProductAttributeList);
                }

                if (entity.Id === 0) {
                    _.remove(this.gvwProductAttribute.data, (o) => {
                        return o === entity;
                    });
                    return;
                }
            }

            removeInlineSupplier(entity: interfaces.master.IProductMasterSupplier){

                if (entity.Id === 0) {
                    _.remove(this.gvwProductSupplier.data, (o) => {
                        return o === entity;
                    });

                      if (this.apiPA) {
                    this.apiProductSupplier.rowEdit.setRowsClean([entity]);
                }

                    return;
                }
                else
                {
                    entity.IsActive = false;
                    this.apiProductSupplier.rowEdit.setRowsDirty([entity]);
                }
            }

            removeInlineAdditionalDescription(entity: interfaces.master.IProductAdditionalDescription){
                if (!entity.shortDescriptionId) {
                    _.remove(this.gvwProductDesc.data, (o) => {
                        return o === entity;
                    });

                      if (this.apiProductDesc) {
                    this.apiProductDesc.rowEdit.setRowsClean([entity]);
                }

                    return;
                }
                else
                {
                    entity.IsActive = false;
                    this.apiProductDesc.rowEdit.setRowsDirty([entity]);
                }
            }

            removeInlineVatRate(entity: interfaces.master.IProductVatRate){
                if (entity.Id === 0) {
                    _.remove(this.gvwProductVatRate.data, (o) => {
                        return o === entity;
                    });

                      if (this.apiProductVatRate) {
                    this.apiProductVatRate.rowEdit.setRowsClean([entity]);
                }

                    return;
                }
                else
                {
                    entity.IsActive = false;
                    this.apiProductVatRate.rowEdit.setRowsDirty([entity]);
                }
            }
     
            checkBoxAttributeChanged(entity) {
                if (this.apiPA) {
                    this.apiPA.rowEdit.setRowsDirty([entity]);
                    }
                }
           

            attributeValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICustomAttributeValue) {
                if (model) {
                    entity.AttributeValue = model.Description;
                    if (this.apiPA) {
                        this.apiPA.rowEdit.setRowsDirty([entity]);
                    }
                }                  
            }


            attributeValueOtherChanged(entity: interfaces.master.ICustomAttributeValue) {
                if (entity) {
                    if (this.apiPA) {
                        this.apiPA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            addSKU(){
                this.productMaster.ProductSKUList.push(<interfaces.master.IProductSKU>{
                    productId: this.productMaster.Id,
                    SKUCode: this.productMaster.Code,
                    SKUDescription: this.productMaster.ShortDescription,                    
                    IsActive: true,
                    IsDefault: this.productMaster.ProductSKUList.length==0?true:false,
                    IsExpanded: true,
                    SKUUoMList: [],
                    SKUSupplierList: []
                });
            }

            insertPA() {
                this.productMaster.ProductAttributeList.push(<interfaces.master.ICustomAttributeValue>{
                    Id: 0,
                    Attribute: <interfaces.master.ICustomAttribute>{ Id: 0, AttributeType: 0, IsNotEditable: false },
                    CategoryType: 137,
                    CategoryKey: this.productMaster.Id,
                    IsActive: true
                });
            }

            registerProductAttributeApi(gridApi: uiGrid.IGridApi) {
                this.apiPA = gridApi;
            }

            loadAttributeList() {
                return this.customAttributeService.GetCustomAttributeByCategory(137, this.productMaster.OwnerEntityId).query({
                }, (resultList) => {
                    this.attributeList = resultList;
                }).$promise;
            }

            loadAttributeAnswerOptionList(id) {
                return this.customAttributeAnswerOptionService.GetDropDownListByAttribute().query({
                    attributeId: id
                }, (resultList) => {
                    this.attributeAnswerOptionList = resultList;
                }).$promise;
            }

            gvwProductAttribute: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-disabled="row.entity.Attribute.IsNotEditable" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerProductAttributeApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productUpdateCtrl.removeInlinePA(row.entity)" ng-disabled="row.entity.Attribute.IsNotEditable" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 56,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productUpdateCtrl.insertPA()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Attribute',
                        displayName: '* Attribute',
                        field: 'Attribute',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="row.entity.Attribute.IsNotEditable">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="row.entity.Attribute.IsNotEditable" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productUpdateCtrl.loadAttributeList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: 'AttributeValue',
                        displayName: '* Attribute Value',
                        field: 'CustomAttributeAnswerOption',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeNumericValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeDateValue | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm" >   
                                        <gts-dropdown ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable" ng-change="grid.appScope.productUpdateCtrl.attributeValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AttributeValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productUpdateCtrl.loadAttributeAnswerOptionList(row.entity.Attribute.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.Attribute.AttributeType == 58">
                                        <input type="text" ng-change="grid.appScope.productUpdateCtrl.attributeValueOtherChanged( row.entity)" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AttributeValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.Attribute.AttributeType == 556">
                                        <input type="number" ng-change="grid.appScope.productUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AttributeNumericValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.productUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable"  format="yyyy/MM/dd" ng-model="row.entity.AttributeDateValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 300
                    }
                    , {
                        name: "Active",
                        displayName: "IsActive",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive" ng-change="grid.appScope.productTariffUpdateCtrl.checkBoxAttributeChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 100
                    }
                ]
            }; 




            SaveProduct(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                var _ProductSupplierList = this.productMaster.ProductSupplierList;
                var _ProductVatRateList = this.productMaster.ProductVatRateList;
                var _ProductAdditionalDescriptionList = this.productMaster.ProductAdditionalDescriptionList;
                var _ProductAttributeList = this.productMaster.ProductAttributeList;


                if (this.apiProductSupplier) {
                    this.productMaster.ProductSupplierList = _.map(this.apiProductSupplier.rowEdit.getDirtyRows(this.apiProductSupplier.grid), (o) => { return o.entity });
                }
                else {
                    this.productMaster.ProductSupplierList = [];
                }

                if (this.apiProductVatRate) {
                    this.productMaster.ProductVatRateList = _.map(this.apiProductVatRate.rowEdit.getDirtyRows(this.apiProductVatRate.grid), (o) => { return o.entity });
                }
                else {
                    this.productMaster.ProductVatRateList = [];
                }

                if (this.apiProductDesc) {
                    this.productMaster.ProductAdditionalDescriptionList = _.map(this.apiProductDesc.rowEdit.getDirtyRows(this.apiProductDesc.grid), (o) => { return o.entity });
                }
                else {
                    this.productMaster.ProductAdditionalDescriptionList = [];
                }

                if (this.apiPA) {
                    this.productMaster.ProductAttributeList = _.map(this.apiPA.rowEdit.getDirtyRows(this.apiPA.grid), (o) => { return o.entity });
                }
                else {
                    this.productMaster.ProductAttributeList = [];
                }

                if (this.productMaster.ProductImageFile && (this.productImageFile === this.productMaster.ProductImageFile.base64))
                {
                    this.productMaster.ProductImageFile = null;
                }

                if (this.productMaster.LabelImageFile && (this.labelImageFile === this.productMaster.LabelImageFile.base64))
                {
                    this.productMaster.LabelImageFile = null;
                }

                if (this.productMaster.AdditionalImage1File && (this.AdditionalImage1File === this.productMaster.AdditionalImage1File.base64))
                {
                    this.productMaster.AdditionalImage1File = null;
                }

                if ( this.productMaster.AdditionalImage2File && this.AdditionalImage2File === this.productMaster.AdditionalImage2File.base64)
                {
                    this.productMaster.AdditionalImage2File = null;
                }
               
                this.productService.save().save(this.productMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);


                    if (this.apiProductSupplier) {
                        this.apiProductSupplier.rowEdit.setRowsClean(this.productMaster.ProductSupplierList);
                    }

                    
                    if (this.apiProductVatRate) {
                        this.apiProductVatRate.rowEdit.setRowsClean(this.productMaster.ProductVatRateList);
                    }

                    if (this.apiProductDesc) {
                        this.apiProductDesc.rowEdit.setRowsClean(this.productMaster.ProductAdditionalDescriptionList);
                    }

                    if (this.apiPA) {
                        this.apiPA.rowEdit.setRowsClean(this.productMaster.ProductAttributeList);
                    }
                    
                    this.proId = Number(data.ID);
                                  
                                    
                    deferre.resolve(true);
                }, (errorResponse) => {
                    
                    if (this.proId === 0)
                    {

                        this.productMaster.ProductSupplierList = _ProductSupplierList;
                        this.productMaster.ProductVatRateList = _ProductVatRateList;
                        this.productMaster.ProductAdditionalDescriptionList = _ProductAdditionalDescriptionList;
                        this.productMaster.ProductAttributeList = _ProductAttributeList;

                        this.gvwProductSupplier.data = this.productMaster.ProductSupplierList;
                        this.gvwProductVatRate.data = this.productMaster.ProductVatRateList;
                        this.gvwProductDesc.data = this.productMaster.ProductAdditionalDescriptionList;
                        this.gvwProductAttribute.data = this.productMaster.ProductAttributeList;
                    }

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            removeProductImageFile(){
                this.productMaster.ProductImageFile = null;
            }

            removeLabelImageFile(){
                this.productMaster.LabelImageFile = null;
            }

            removeAdditionalImage1File(){
                this.productMaster.AdditionalImage1File = null;
            }

            removeAdditionalImage2File(){
                this.productMaster.AdditionalImage2File = null;
            }

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pro.update'
                },
                    () => {
                        return this.SaveProduct().then((data: boolean) => {
                            if (data) {
                                this.loadProduct(this.proId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Product.Update", { proId: this.proId});
                            }
                        });
                    });

            }


            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pro.update'
                },
                    () => {
                        return this.SaveProduct().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            SaveTariff() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pro.update'
                },
                    () => {
                        return this.SaveProduct().then((data) => {
                            if (data) {
                                if (this.$state.current.name === "auth.MasterData.Product.Update") {
                                    this.loadProduct(this.proId);
                                    this.$state.go("auth.MasterData.Product.Update.Tariff", { tprId: this.proId});
                                }
                                else
                                {
                                    this.$state.go("^");
                                }
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

        };

        angular.module("app").controller("productUpdateCtrl", controllers.master.productUpdateCtrl);
    }
}
